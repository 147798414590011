import { useNavigate, useParams } from "react-router-dom";
import Back from '../../../components/back'
import { handleRequestErrors } from "utils/helpers";
import { useEffect, useState } from "react";
import { BusinessService } from "services/business.service";
import DetailsBox from "./components/DetailsBox";
import Button from "components/shared/Button";
import WarningModal from "components/Modal/WarningModal";
import approveIcon from "images/svg/approveIcon.svg";
import declineIcon from "images/svg/declineIcon.svg";
import pendingIcon from "images/svg/pendingIcon.svg";
import { toast } from "react-toastify";


function Details () {
    let navigate = useNavigate()
    let params = useParams()
    const [businessData, setBusinessData] = useState(null);
    const [warning, setWarning] = useState({
        show: false,
        type: "", // approve | decline | pending
        isLoading: false,
        reason: "",
    })

    useEffect(() => {
        BusinessService.viewBusiness(params.id)
            .then(response => response.data)
            .then((res) => {
                if(res.status === 'success'){
                    setBusinessData(res.data)
                }
            })
            .catch((err) => {
                handleRequestErrors(err)
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleApproval = () => {
        const status =  warning.type==="approve" ? "approved"
            : warning.type==="decline" ? "declined"
            : "rejected";

            console.log("handle")

        let payload = {
            businessId: params.id,
            status,
            ...(!!warning.reason ? {reason: warning.reason}:{})
        }
        setWarning({...warning, isLoading: true})
        BusinessService.approvalAction(payload)
            .then(response => response.data)
            .then((res) => {
                setWarning({...warning, isLoading: false})
                if(res.status === 'success') {
                    toast.success(res.message)
                    setWarning({...warning, reason: "", show: false})
                }
            })
            .catch((err) => {
                setWarning({...warning, isLoading: false})
                handleRequestErrors(err)
            });
    }

    return (
        <div>
            <Back to={()=> navigate(-1)} />
            <DetailsBox businessData={businessData} />
            <div className="-mt-6 mb-10 flex gap-[20px]">
                <Button
                    text="Approve"
                    onClick={()=>{
                        setWarning({
                            ...warning,
                            show: true,
                            type: "approve",
                        })
                    }}
                    className="bg-[#47D800] px-5 py-2"
                />
                <Button
                    text="Decline"
                    onClick={()=>{
                        setWarning({
                            ...warning,
                            show: true,
                            type: "decline",
                        })
                    }}
                    className="bg-[#FB2C2C] px-5 py-2"
                />
                <Button
                    text="Pending"
                    onClick={()=>{
                        setWarning({
                            ...warning,
                            show: true,
                            type: "pending",
                        })
                    }}
                    className="bg-[#F79009] px-5 py-2"
                />
            </div>
            <WarningModal
                show={warning.show}
                isLoading={warning.isLoading}
                handleClose={()=>setWarning({...warning, show: false})}
                handleAction={()=>{
                    console.log(warning)
                    handleApproval()
                }}
                icon={
                    warning.type==="approve" ? approveIcon
                    : warning.type==="decline" ? declineIcon
                    : pendingIcon
                }
                title={
                    warning.type==="approve" ? "Business Verification Approved"
                    : warning.type==="decline" ? "Business Verification Declined"
                    : "Business Verification Pending"
                }
                text={
                    warning.type==="approve" ? "Are you sure you want to Approve this Verification? This action cannot be undone."
                    : warning.type==="decline" ? "Are you sure you want to Decline this Verification? This action cannot be undone."
                    : (
                        <div className="box-border">
                            <p>Give Reason(s) for pending verification</p>
                            <textarea 
                                placeholder="Reason" 
                                rows={5} 
                                className="bg-[#F2F4F7] outline-none w-full p-[20px]" 
                                onChange={(e)=>{
                                    setWarning({
                                        ...warning,
                                        reason: e.target.value
                                    })
                                }}
                            />
                        </div>
                    )
                }
                type={warning?.type==="approve" ? "success" : warning?.type==="decline" ? "warning" : "pending"}
            />
        </div>
    )
}

export default Details;